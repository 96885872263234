.detail-index-top-right {
  .map-detail-top-title {
    font-size: (22rem / 100);
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    line-height: (34rem / 100);
    color: #0f0f0f;
    margin-bottom: (15rem / 100);
    width: (496rem / 100);
    height: (64rem / 100);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .maplist-detail-top-des {
    margin-bottom: (78rem / 100);

    &-ul {
      width: 100%;
      font-size: (16rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #273143;
      line-height: (22rem / 100);
      color: #232323;

      &-li {
        height: (24rem / 100);
        margin-bottom: (10rem / 100);
        display: flex;
        align-items: center;
      }
    }
  }

  .maplist-detail-top-button {
    width: (149rem / 100);
    height: (50rem / 100);
    background: #761f1e;
    border-radius: (50rem / 100);
    text-align: center;
    line-height: (50rem / 100);
    font-size: (16rem / 100);
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    color: #ffffff;
    cursor: pointer;
  }
}

.detail-index-bottom-content {
  padding: 0 (16rem / 100) (146rem / 100) 0;

  &-top {
    width: (1200rem / 100);
    height: (100rem / 100);
    background: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_bottom_top_bg.png")
      no-repeat;
    background-size: 100% 100%;
    box-shadow: 0 5px 15px 0 rgba(207, 181, 140, 0.8);
    display: flex;
    align-items: center;
    padding-left: (30rem / 100);
    margin-bottom: (28rem / 100);

    &-title {
      width: (140rem / 100);
      height: (31rem / 100);
      text-align: center;
      line-height: (31rem / 100);
      font-size: (20rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
      background: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_title_bg.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }

  .detail-index-bottom-content-bottom-box {
    margin-bottom: (44rem / 100);
    padding: 0 (30rem / 100);

    &-top {
      display: flex;
      align-items: center;
      font-size: (16rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #273143;
      line-height: (16rem / 100);
      margin-bottom: (15rem / 100);

      &-icon {
        width: (9rem / 100);
        height: (16rem / 100);
        margin-right: (7rem / 100);
      }
    }

    &-bottom {
      padding-left: (16rem / 100);
      font-size: (14rem / 100);
      font-family: PingFangSC-Regular, PingFang SC;
      color: #273143;
      line-height: (28rem / 100);

      &-li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: (16rem / 100);
        font-size: (14rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (14rem / 100);

        &-left {
          display: flex;
          align-items: center;
        }
      }

      .volunteer-detail-bottom-activity-ul-li-status {
        width: (72rem / 100);
        height: (30rem / 100);
        border-radius: (20rem / 100);
        line-height: (30rem / 100);
        text-align: center;
        font-size: (14rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        margin-right: (12rem / 100);
      }

      .volunteer-sign {
        background: rgba($color: #761f1e, $alpha: 0.1);
        color: #761f1e;
      }

      .volunteer-starting {
        background: rgba($color: #761f1e, $alpha: 1);
        color: #ffffff;
      }

      .volunteer-over {
        background: #888888;
        color: #ffffff;
      }

      .volunteer-detail-bottom-activity-ul-li-right {
        a {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
